import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { SharedService } from '../shared.service';
import * as htmlToImage from 'html-to-image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';

@Component({
  selector: 'app-email-editor-v3',
  templateUrl: './email-editor-v3.component.html',
  styleUrls: ['./email-editor-v3.component.scss']
})
export class EmailEditorV3Component {

  iconSelectItem: any = {};

  saving: any = false;

  hidden: any = true;

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  blockTab = "CONTENT";
  opportunityId: any = '';
  opportunityObject: any = {};
  loading = true;
  newMessageBody = '';

  activeBlock: any = {};

  fetchingSiteData = true;
  siteData: any = {};

  logoImage = "";
  primaryColor = "";

  templateObject = {
    templateContentBackground: "#f7f5ef",
    templateBackground: "#fff",
    blocks: [
      {
        type: "LOGO",
        description: "shows the logo of the brand",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/logo_scf.png?v=1671807225",
          },
        ],
      },
      {
        type: "TITLE",
        description: "Title & subtitle of the email",
        properties: [
          {
            name: "Title",
            type: "INPUT",
            value: "Don't let your cart slip away",
          },
          {
            name: "Subtitle",
            type: "INPUT",
            value: "Looks like you left some items in your cart",
          },
        ],
      },
      {
        type: "BUTTON",
        description: "Button the user can click to navigate to the brands website",
        properties: [
          {
            name: "Button text",
            type: "INPUT",
            value: "Go to my cart",
          },
          {
            name: "Button color",
            type: "INPUT",
            value: "#fff",
          },
        ],
      },
      {
        type: "IMAGEFULLWIDTH",
        description: "Bold image that covers the full width of the email",
        properties: [
          {
            name: "Image URL",
            type: "INPUT",
            value: "https://cdn.shopify.com/s/files/1/0664/2103/1145/products/ribeye.png?v=1664787645&width=533",
          },
        ],
      },
    ]
  }

  blockLibrary = [
    {
      title: "Hero",
      icon: "home",
      type: "HERO"
    },
    {
      title: "Image",
      icon: "file-image",
      type: "IMAGE",
      properties: [
        {
          name: "Image",
          type: "INPUT",
          value: "https://cdn.shopify.com/s/files/1/0122/2986/9664/products/Pebble-mainslider3_1080x.png?v=1666930457",
        },
        {
          name: "Background color",
          type: "INPUT",
          value: "",
        },
        {
          name: "Layout",
          type: "SELECT",
          value: {
            title: "Full width",
            value: "FULLWIDTH"
          },
          category: "STYLES",
          options: [
            {
              title: "Standard",
              value: "STANDARD"
            },
            {
              title: "Full width",
              value: "FULLWIDTH"
            }
          ]
        },
      ]
    },
    {
      title: "Article",
      icon: "book",
      type: "ARTICLE"
    },
    {
      title: "Features",
      icon: "appstore",
      type: "FEATURES"
    }
  ]

  collections: any = [];

  loadingDynamicProducts: any = false;
  backMode: any = "";

  remixCopyFeedback: any = "";
  remixCopyPopoverVisible: any = false;
  remixingColors: any = false;

  iconSearchTerm: any = "";
  iconSearchMode: any = false;
  iconSearchList: any = [];

  selectedIndex = 0;

  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;

  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private scroll: ViewportScroller,
    public sharedService: SharedService,
    private message: NzMessageService,
    private location: Location
  ) { }

  ngOnInit() {
    this.sharedService.loadingRenderer = true;
    this.sharedService.rendererOpportunityObject = {};
    this.sharedService.currentBlock = {};
    this.activeBlock = {};
    this.opportunityId = this.route.snapshot.paramMap.get("id");
    this.backMode = this.route.snapshot.paramMap.get("mode");

    console.log(this.opportunityId);
    this.apiService.getOpportunity(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.sharedService.rendererOpportunityObject = data;
        this.sharedService.rendererOpportunityObject = data;
        this.loading = false;
        // if (this.sharedService.currentUser.workspace._id == data.workspace) {
        //   this.sharedService.rendererOpportunityObject = data;
        //   this.loading = false;
        // } else {
        //   this.router.navigate(['/']);
        // }

        if (this.sharedService.rendererOpportunityObject.stage == "NEW") {
          this.apiService.generateTemplateContent(this.opportunityId).subscribe(
            (data: any) => {
              console.log(data);
              this.sharedService.rendererOpportunityObject = data;
              this.loading = false;
              this.fetchingSiteData = false;
              this.siteData = data;
            },
            error => {
              console.log(error);
            });
        }
      },
      error => {
        console.log(error);
      });

    this.apiService.getCurrentWorkspace().subscribe(
      (data: any) => {
        console.log(data);


        // this.workspace = data;
        this.sharedService.workspace = data;
        // this.loading = false;
      },
      error => {
        console.log(error);
      });

    this.apiService.getCollections().subscribe(
      (data: any) => {
        console.log(data);

        data.unshift({
          data: {
            title: "All",
          }
        })

        // this.workspace = data;
        this.collections = data;
        // this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  generateProposal() {
    this.sharedService.rendererOpportunityObject.stage = "NEW";
    this.apiService.generateProposal(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.sharedService.rendererOpportunityObject = data;
        this.loading = false;

      },
      error => {
        console.log(error);
      });
  }

  generateTemplateContent() {
    this.sharedService.rendererOpportunityObject.stage = "NEW";
    this.fetchingSiteData = true;
    this.apiService.generateTemplateContent(this.opportunityId).subscribe(
      (data: any) => {
        console.log(data);
        this.sharedService.rendererOpportunityObject = data;
        this.loading = false;
        this.fetchingSiteData = false;

      },
      error => {
        console.log(error);
      });
  }

  newMessage() {
    this.apiService.newMessage({
      body: this.newMessageBody,
      opportunity: this.opportunityId
    }).subscribe(
      (data: any) => {
        console.log(data);
      },
      error => {
        console.log(error);
      });
  }

  selectBlock(block: any) {
    this.activeBlock = block;
    this.sharedService.currentBlock = block;
  }

  selectPrimaryColor(color: any) {
    this.primaryColor = color;
  }

  selectLogoImage(image: any) {
    this.logoImage = image;
  }

  saveTemplate() {
    this.saving = true;
    for (let block of this.sharedService.rendererOpportunityObject.contentTemplate.blocks) {
      if (this.getArrayItem(block.properties, 'Components') && this.getArrayItem(block.properties, 'Components').items) {
        for (let component of this.getArrayItem(block.properties, 'Components').items) {
          delete component.quillEditor;
        }
      }
    }
    this.apiService.saveTemplate(this.sharedService.rendererOpportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Your changes have been saved`);
        // const currentUrl = this.router.url;
        // const routeConfig = this.router.config;
        // const currentIndex = routeConfig.findIndex(route => route.path === currentUrl);
        // console.log(routeConfig);
        // console.log(currentIndex);

        // if (currentIndex > 0) {
        // } else {
        //   this.router.navigate([`/opportunities/${this.sharedService.rendererOpportunityObject._id}`]);
        // }
      },
      error => {
        console.log(error);
      });
  }

  saveTemplateAndExit() {
    this.saving = true;
    this.apiService.saveTemplate(this.sharedService.rendererOpportunityObject).subscribe(
      (data: any) => {
        console.log(data);
        this.saving = false;
        this.message.create("success", `Your changes have been saved`);
        // const currentUrl = this.router.url;
        // const routeConfig = this.router.config;
        // const currentIndex = routeConfig.findIndex(route => route.path === currentUrl);
        // console.log(routeConfig);
        // console.log(currentIndex);

        if (this.backMode == "opp" || this.backMode == "new") {
          this.router.navigate([`/opportunities/${this.sharedService.rendererOpportunityObject._id}`]);
        } else {
          this.location.back();
        }

        // if (currentIndex > 0) {
        // } else {
        //   this.router.navigate([`/opportunities/${this.sharedService.rendererOpportunityObject._id}`]);
        // }
      },
      error => {
        console.log(error);
      });
  }

  goBack() {
    if (this.backMode == "opp") {
      this.router.navigate([`/opportunities/${this.sharedService.rendererOpportunityObject._id}`]);
    } else {
      this.location.back();
    }
  }

  getPropertyValue(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0].value;
    } else {
      return "";
    }
  }

  getProperty(block: any, propertyName: any) {
    let property = block.properties.filter((obj: any) => {
      return obj.name === propertyName;
    });

    return property[0];
  }

  drop(event: any) {
    console.log(event);
    moveItemInArray(this.sharedService.rendererOpportunityObject.contentTemplate.blocks, event.previousIndex, event.currentIndex);
  };

  dropProduct(event: any, productArray: any) {
    console.log(event);
    moveItemInArray(productArray, event.previousIndex, event.currentIndex);
  };

  moveDown(block: any, index: any) {
    console.log(block, index);
    if (index != this.sharedService.rendererOpportunityObject.contentTemplate.blocks.length - 1) {
      moveItemInArray(this.sharedService.rendererOpportunityObject.contentTemplate.blocks, index, index + 1);
    }

    let el: any = document.getElementById('logo')
    el.scrollIntoView(true);
  }

  moveUp(block: any, index: any) {
    console.log(block, index);
    if (index != 0) {
      moveItemInArray(this.sharedService.rendererOpportunityObject.contentTemplate.blocks, index, index - 1);
    }
    // let el: any = document.getElementById('logo')
    // el.scrollIntoView(true);
  }

  addBlock(block: any, oldBlock: any) {
    this.sharedService.rendererOpportunityObject.contentTemplate.blocks.unshift(block);
    // oldBlock.showPalette = false;
    this.cdr.detectChanges();
    this.scroll.scrollToPosition([0, 0]);
  }

  removeItem(index: any) {
    this.sharedService.rendererOpportunityObject.contentTemplate.blocks.splice(index, 1);
  }

  removeItemFromArray(index: any, list: Array<any>) {
    list.splice(index, 1);
  }

  openPicker(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
  }

  openCollectionPicker(property: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.collectionPickerOpen = true;
  }

  openPickerSingle(property: any, mode: any) {
    console.log("Open Picker");
    this.sharedService.currentProperty = property;
    this.sharedService.pickerMode = mode;
    this.sharedService.pickerOpen = true;
    this.sharedService.pickerNumber = "SINGLE";
  }

  openBlockLibrary(index: any) {
    console.log("Open block library: ", index);
    this.sharedService.currentTemplate = this.sharedService.rendererOpportunityObject.contentTemplate;
    this.sharedService.blockLibraryMode = "NEWBLOCK";
    this.sharedService.blockLibraryOpen = true;
    this.sharedService.blockIndex = index;
  }

  openVariantLibrary(property: any) {
    this.sharedService.currentTemplate = this.sharedService.rendererOpportunityObject.contentTemplate;
    this.sharedService.currentProperty = property;
    this.sharedService.currentBlock = this.activeBlock;
    this.sharedService.blockLibraryMode = "CHANGEVARIANT";
    this.sharedService.blockLibraryOpen = true;
  }

  pushDataListValue(property: any) {
    property.value.push(JSON.parse(JSON.stringify(property.dataTemplate)));
  }

  getArrayItem(array: any, propertyName: any) {
    let property = array.filter((obj: any) => {
      return obj.name === propertyName;
    });

    if (property[0]) {
      return property[0];
    } else {
      return "";
    }
  }

  nextVariant(property: any) {
    let index = property.options.findIndex((variant: any) => variant.value === property.value.value);

    if (index < property.options.length - 1) {
      property.value = property.options[index + 1];
    } else {
      property.value = property.options[0];
    }

    this.cdr.detectChanges();
  }

  previousVariant(property: any) {
    let index = property.options.findIndex((variant: any) => variant.value === property.value.value);

    if (index > 0) {
      property.value = property.options[index - 1];
    } else {
      property.value = property.options[property.options.length - 1];
    }

    this.cdr.detectChanges();
  }

  colorChosen(value: any) {
    console.log(value);

    if (value.length == 7) {
      // Check if color already in palette 
      let index = this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette.findIndex((color: any) => color.toLowerCase() == value.toLowerCase());
      console.log(index);

      if (index == -1) {
        this.sharedService.rendererOpportunityObject.contentTemplate.colorPalette.push(value);
      }
    }
  }

  isEven(n: any) {
    return n % 2 == 0;
  }

  generateImage(id: any) {
    var node: any = document.getElementById(id);
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        console.log(img);
        img.src = dataUrl;
        return img;
        // document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  generateEmailImages() {
    let imageArray: any = [];

    for (let block in this.sharedService.rendererOpportunityObject.contentTemplate.blocks) {
      let currentBlock = this.sharedService.rendererOpportunityObject.contentTemplate.blocks[block];

      var node: any = document.getElementById(`block-${block}`);
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          var img = new Image();
          console.log(img);
          img.src = dataUrl;
          imageArray.push(img);
          // document.body.appendChild(img);
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
    console.log(imageArray);
  }

  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  compareFn2 = (o1: any, o2: any): boolean => (o1 && o2 ? o1.title === o2.title : o1 === o2);
  compareFn3 = (o1: any, o2: any): boolean => (o1 && o2 ? o1.data.title === o2.data.title : o1 === o2);

  getDynamicProducts(destination: any, query: any) {
    console.log(destination);
    this.loadingDynamicProducts = true;
    const id = this.message.loading('Loading products...', { nzDuration: 0 }).messageId;
    this.apiService.getDynamicProducts(query).subscribe((products: any) => {
      console.log(products);
      for (let product of products) {
        if (!product.buttonText) {
          product.buttonText = "Shop now";
        }
      }
      destination.value = products;
      this.cdr.detectChanges();
      this.loadingDynamicProducts = false;
      this.message.remove(id);
      this.message.create("success", `Loaded products`);
    });
  }

  onToggleChange(event: any, block: any) {
    console.log(event);
    console.log(block);
    block.value = event;
    console.log(this.sharedService.rendererOpportunityObject);
    this.cdr.detectChanges();
  }

  chooseIcon(target: any, icon: any) {
    target.value = icon;
    target.popoverVisible = false;
  }

  openImageEditor(imageObject: any) {
    if (imageObject.imageUrl) {
      imageObject.value = imageObject.imageUrl;
    }

    this.sharedService.editImageOpen = true;
    this.sharedService.editImageCurrentImage = imageObject;
  }

  openImageBackgroundEditor(imageObject: any) {
    if (imageObject.imageUrl) {
      imageObject.value = imageObject.imageUrl;
    }

    this.sharedService.editImageBackgroundOpen = true;
    this.sharedService.editImageBackgroundCurrentImage = imageObject;
  }

  positiveRating() {
    this.sharedService.rendererOpportunityObject.contentTemplate.ratingInfo = "POSITIVE";
  }

  negativeRating() {
    this.sharedService.rendererOpportunityObject.contentTemplate.ratingInfo = "NEGATIVE";
  }

  async remixCopy() {
    let updatedOpp: any = await this.apiService.generateTemplateContent2(this.sharedService.rendererOpportunityObject._id, "REMIXCOPY", this.remixCopyFeedback).toPromise();
    console.log(updatedOpp);
  }

  async shuffleColors() {
    this.remixingColors = true;
    this.sharedService.aiModalLoading = true;
    this.sharedService.aiModalMessage = "Shuffling colors...";
    // const id = this.message.loading('Shuffling colors...', { nzDuration: 0 }).messageId;

    let oldColorScheme = [];
    for (let i = 0; i < this.sharedService.rendererOpportunityObject.contentTemplate.blocks.length; i++) {
      let currentBlock = this.sharedService.rendererOpportunityObject.contentTemplate.blocks[i];
      if (currentBlock.gptBlock?.template && currentBlock.gptBlock.template[0] && currentBlock.gptBlock.template[0].fields) {
        oldColorScheme.push(currentBlock.gptBlock.template[0].fields.colors);
      } else {
        oldColorScheme.push({});
      }
    }

    let updatedOpp: any = await this.apiService.generateTemplateContent2(this.sharedService.rendererOpportunityObject._id, "SHUFFLECOLORS", "", oldColorScheme).toPromise();
    console.log(updatedOpp);

    for (let block in this.sharedService.rendererOpportunityObject.contentTemplate.blocks) {
      let newBlock = this.sharedService.rendererOpportunityObject.contentTemplate.blocks[block];
      let colors = updatedOpp.result.result[block];
      console.log(newBlock);
      console.log(colors);

      if (colors.backgroundColor) {

        newBlock.gptBlock.template[0].fields.colors = colors;

        if (newBlock.type == "HERO") {
          if (this.getArrayItem(newBlock.properties, 'Layout').value.value == "STANDARD" || this.getArrayItem(newBlock.properties, 'Layout').value.value == "OVERCONTAINEDIMAGE" || this.getArrayItem(newBlock.properties, 'Layout').value.value == "OVERCONTAINEDIMAGELEFT" || this.getArrayItem(newBlock.properties, 'Layout').value.value == "TITLEONIMAGEVERTICAL" || this.getArrayItem(newBlock.properties, 'Layout').value.value == "TITLEONIMAGE") {
            colors.textColor = "#FFFFFF";
          }

          this.getArrayItem(newBlock.properties, 'Button color').value = colors.buttonColor;
          this.getArrayItem(newBlock.properties, 'Button text color').value = colors.buttonTextColor;
          this.getArrayItem(newBlock.properties, 'Text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Title color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Subtitle color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Background color').value = colors.backgroundColor;
        }

        if (newBlock.type == "REVIEW") {
          this.getArrayItem(newBlock.properties, 'Title color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Name color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Background color').value = colors.backgroundColor;
        }

        if (newBlock.type == "PRODUCT") {
          this.getArrayItem(newBlock.properties, 'Text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Description text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Price text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Compare at price text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Title text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Subtitle text color').value = colors.textColor;
          this.getArrayItem(newBlock.properties, 'Product button color').value = colors.buttonColor;
          this.getArrayItem(newBlock.properties, 'Product button text color').value = colors.buttonTextColor;
          this.getArrayItem(newBlock.properties, 'Background color').value = colors.backgroundColor;
          this.getArrayItem(newBlock.properties, 'Button text color').value = colors.buttonTextColor;
          this.getArrayItem(newBlock.properties, 'Button color').value = colors.buttonColor;
        }
      }
    }

    // this.message.remove(id);
    this.message.create("success", `Shuffled colors`);
    this.remixingColors = false;
    this.sharedService.aiModalLoading = false;
  }

  openReviewPicker() {
    this.sharedService.reviewPickerOpen = true;
  }

  searchIcons() {
    if (this.iconSearchTerm == "") {
      this.iconSearchMode = false;
    } else {
      this.iconSearchMode = true;
      this.iconSearchList = this.sharedService.iconLibrary.icons.filter(item => item.value.includes(this.iconSearchTerm));
    }
  }

  setQuillAlignment(direction: string) {
    if (direction === 'left') {
      this.sharedService.activeComponent.quillEditor.format('align', false); // Removes the alignment formatting
    } else {
      this.sharedService.activeComponent.quillEditor.format('align', direction);
    }
  }

  setQuillColor(property: any) {
    const quill = this.sharedService.activeComponent.quillEditor;

    if (this.sharedService.activeComponent.type == "TEXT" || this.sharedService.activeComponent.type == "BUTTON") {
      const selection = quill.getSelection();
      console.log(selection);

      // If nothing is selected, select the entire text
      if (!selection || selection.length == 0) {
        const length = quill.getLength(); // Get the total length of the text
        quill.setSelection(0, length - 1); // Select the entire text
      }

      
      this.sharedService.activeComponent.quillEditor.format('color', property.props.color);
      // const quill = this.sharedService.activeComponent.quillEditor;
      // const range = quill.getSelection();

      // // Check if there is a selection
      // if (range && range.length > 0) {
      //   // Apply the color format to the selected text
      //   quill.format('color', property.value);
      // } else {
      //   // Get the entire length of the editor content
      //   const length = quill.getLength();

      //   // Apply the color format to the entire range
      //   quill.formatText(0, length, 'color', property.value);
      // }
    }
  }

  setQuillBold() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const selection = quill.getSelection();
    console.log(selection);

    // If nothing is selected, select the entire text
    if (!selection || selection.length == 0) {
      const length = quill.getLength(); // Get the total length of the text
      quill.setSelection(0, length - 1); // Select the entire text
    }

    // Get the current format after setting the selection
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('bold', !currentFormat.bold);
  }

  setQuillItalic() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const selection = quill.getSelection();
    console.log(selection);

    // If nothing is selected, select the entire text
    if (!selection || selection.length == 0) {
      const length = quill.getLength(); // Get the total length of the text
      quill.setSelection(0, length - 1); // Select the entire text
    }

    // Get the current format after setting the selection
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('italic', !currentFormat.italic);
  }

  setQuillUnderline() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const selection = quill.getSelection();
    console.log(selection);

    // If nothing is selected, select the entire text
    if (!selection || selection.length == 0) {
      const length = quill.getLength(); // Get the total length of the text
      quill.setSelection(0, length - 1); // Select the entire text
    }

    // Get the current format after setting the selection
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('underline', !currentFormat.underline);
  }

  setQuillStrikethrough() {
    // Get the current format
    const quill = this.sharedService.activeComponent.quillEditor;
    const selection = quill.getSelection();
    console.log(selection);

    // If nothing is selected, select the entire text
    if (!selection || selection.length == 0) {
      const length = quill.getLength(); // Get the total length of the text
      quill.setSelection(0, length - 1); // Select the entire text
    }

    // Get the current format after setting the selection
    const currentFormat = quill.getFormat();

    // Toggle format
    quill.format('strike', !currentFormat.strike);
  }

  setQuillBullets() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'bullet') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'bullet');
    }
  }

  setQuillOrderedList() {
    const quill = this.sharedService.activeComponent.quillEditor;

    // Get the current format of the selection
    const currentFormat = quill.getFormat();

    // If the current format is 'bullet', remove the list formatting
    if (currentFormat.list === 'ordered') {
      quill.format('list', false);
    } else {
      // Otherwise, set the format to 'bullet'
      quill.format('list', 'ordered');
    }
  }

  handleModelChange(index: number, property: any): void {
    if (index == 0) {
      property.props.backgroundMode = "solid";
    }

    if (index == 1) {
      property.props.backgroundMode = "image";
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      /* returns x here */
      this.scrollableDiv.nativeElement.scrollTop = 0;
    }, 500);
  }

  onSwitchClick(event: MouseEvent) {
    // Prevent the click event from propagating to the parent element
    event.stopPropagation();
    console.log('Switch clicked, redirect prevented.');
  }

  clickComponent(item: any) {
    this.sharedService.activeComponent = item;
  }

  clearBlocks() {
    this.sharedService.currentBlock = {}
    this.sharedService.activeComponent = null;
  }
}
