import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SharedService } from '../shared.service';
import Quill from 'quill';

@Component({
  selector: 'app-component-renderer-2',
  templateUrl: './component-renderer-2.component.html',
  styleUrls: ['./component-renderer-2.component.scss']
})
export class ComponentRendererV2Component {
  @Input() data: any;
  @Input() childData: any;
  @Input() value: any;
  @Input() dynamicMode: any;
  @Input() item: any;
  @Input() object: any;
  @Input() objectField: any;
  modules: any = {
    // 'emoji-shortname': true,
    // 'emoji-textarea': true,
    // 'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      // ['emoji'],
      ['clean']                                      // remove formatting button
    ]
  };
  modules2: any = {
    // 'emoji-shortname': true,
    // 'emoji-textarea': true,
    // 'emoji-toolbar': true,
    toolbar: false
  };
  placeholder = "";
  activeStyles = {
    outline: "2px dashed #00809C"
  };

  imageUrl = "";
  textValue = "";

  // @ViewChild('quillEditor', { static: false }) quillEditor!: ElementRef;

  constructor(
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit() {

    if (this.data.type == "IMAGE") {
      if (this.value) {
        this.imageUrl = this.value;
      } else {
        console.log(this.data);
        this.imageUrl = this.data.imageUrl;

        if (!this.data.imageUrl) {
          this.imageUrl = this.data.value;
        }
      }
    }

    if (this.data.type == "TEXT") {
      if (this.value) {
        this.textValue = this.value;
      } 
    }

    if (this.dynamicMode) {
      this.data.dynamicMode = true;
    }

    if (this.data.type == "BUTTON") {
      if (!this.data.content) {
        this.data.content = "Button";
      }
    }

    // if (this.value && this.data.type == "TEXT") {
    //   this.textValue = this.value.value;
    // } else {
    //   this.textValue = this.data.content;
    // }

  }

  ngAfterViewInit() {
    // Ensure the quill editor is accessible after the view has initialized
    // this.setLineHeight(this.lineHeight);
  }

  onEditorCreated(quill: any) {
    this.data.quillEditor = quill;
    console.log(this.data);

    quill.formatText(0, quill.getLength(), {
      'align': this.data.props.textAlign // Aligns text to the center
    });

    // Get the entire length of the editor content
    const length = quill.getLength();

    // Apply the color format to the entire range
    quill.formatText(0, length, 'color', this.data.props.color);

  }

  checkIfActive() {
    return this.sharedService.activeComponent?.key == this.data?.key;
  }

  addButton(id: any) {
    this.sharedService.addButton(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addText(id: any) {
    this.sharedService.addText(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addImage(id: any) {
    this.sharedService.addImage(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addContainer(id: any) {
    this.sharedService.addContainer(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addProduct(id: any) {
    this.sharedService.addProduct(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  addReview(id: any) {
    this.sharedService.addReview(id);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  deleteComponent(id: any) {
    this.sharedService.findAndDelete(this.sharedService.newEditorCurrentOpp.contentTemplate.template.children, id)
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }

  duplicateComponent(id: any, component: any) {
    this.sharedService.insertDuplicateComponent(id, component);
    this.sharedService.newEditorCurrentOpp.contentTemplate.template.children = [...this.sharedService.newEditorCurrentOpp.contentTemplate.template.children];
    this.cdr.detectChanges();
  }
}
